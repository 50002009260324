import React from 'react';
import gql from 'graphql-tag';

import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import ChatIcon from '@material-ui/icons/Chat';

import { ButtonLink } from '../UI';
import { useAuthContext } from '../../hooks';
import { HomeLink } from './Header.style';

export const GET_USER = gql`
  query User($id: ID!) {
    findUserAccountByID(id: $id) {
      credits
    }
  }
`;

const ResponsiveMenu = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const { loggedIn, actions } = useAuthContext();
  return (
    <>
      <HomeLink onClick={() => setOpen(true)}>
        <MenuIcon />
      </HomeLink>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <List>
          <ListItem>
            <ButtonLink to="/">
              <ChatIcon />
              Scriptic
            </ButtonLink>
          </ListItem>
          <ListItem>
            <ButtonLink to="/">
              HOME
            </ButtonLink>
          </ListItem>
          <ListItem>
            <ButtonLink to="/trails">
              PLAY
            </ButtonLink>
          </ListItem>
          <ListItem>
            <ButtonLink to="/scripts/add">
              CREATE
            </ButtonLink>
          </ListItem>
          {loggedIn && (
            <ListItem>
              <ButtonLink to="/host">
                HOSTED GAMES
              </ButtonLink>
            </ListItem>
          )}
          {loggedIn && (
            <>
              <ListItem>
                <ButtonLink to="/scripts">
                  SCRIPTS
                </ButtonLink>
              </ListItem>
              <ListItem>
                <ButtonLink to="/play">
                  PLAYED GAMES
                </ButtonLink>
              </ListItem>
            </>
          )}
        </List>
        <Divider />
        <List>
          <ListItem>
            {data && data.findUserAccountByID && (
              <ButtonLink to="/topup">
                <Chip
                  variant="outlined"
                  size="small"
                  avatar={<Avatar>C</Avatar>}
                  label={data.findUserAccountByID.credits}
                />
              </ButtonLink>
            )}
          </ListItem>
          <ListItem>
            <Button onClick={actions.showModal} color="inherit" aria-label="menu">
              {loggedIn ? 'Log out' : 'Log in'}
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default ResponsiveMenu;
